import { Routes as DomRoutes, Route } from "react-router-dom";
import App from "../App";
import {
  ClassSubject,
  ClusterDetails,
  Clusters,
  ClustersWrapper,
  Dashboard,
  AdminDashboard,
  Login,
  Staff,
  Students,
  StudentsWrapper,
  Users,
} from "../Pages";
import Attendance from "../Pages/Attendance";
import Fee from "../Pages/Fee";
import Transactions from "../Pages/Library/Transactions";
import Library from "../Pages/Library";
import PasswordRequest from "../Pages/PasswordRequest";
import SchoolDetails from "../Pages/Schools/SchoolDetail";
import StaffDeatils from "../Pages/Staff/Details";
import AddStudents from "../Pages/Students/AddStudents";
import StudentDetails from "../Pages/Students/Details";
import Syllabus from "../Pages/Syllabus";
import Test from "../Pages/Test";
import UserDetails from "../Pages/Users/Details";
import Variables from "../Pages/Variables";
import SyllabusAddOrView from "../Pages/Syllabus/SyllabusAddOrView";
import SyllabusWrapper from "../Pages/Syllabus/SyllabusWrapper";
import Rollback from "../Pages/Rollback/Rollback";
import LibraryWrapper from "../Pages/Library/LibraryWrapper";
import WorkShopWrapper from "../Pages/WorkShop/WorkShopWrapper";
import Participant from "../Pages/WorkShop/Participant";
import WorkShop from "../Pages/WorkShop";
import ClassObservation from "../Pages/ClassObservation";
import SchoolRecord from "../Pages/SchoolRecord";
import ClassObservationForm from "../Pages/ClassObservation/ClassObservationForm";
import StaffAttendance from "../Pages/StaffAttendance";
import SchoolAssembly from "../Pages/SchoolAssembly";
import WeeklyAssemblyRecord from "../Pages/WeeklyAssemblyRecord";
import ReportsWrapper from "../Pages/Reports/ReportsWrapper";
import DailyEntries from "../Pages/Reports/DailyEntries";
import FeeCollectionV1 from "../Pages/Reports/FeeCollectionV1";
import ParentInterview from "../Pages/ParentInterview";
import TestWrapper from "../Pages/Test/TestWrapper";
import TestResults from "../Pages/Test/TestResults";
import CoCurricular from "../Pages/CoCurricular";
import Cap from "../Pages/Cap";
import Ptm from "../Pages/Ptm";
import CoCurricularWrapper from "../Pages/CoCurricular/CoCurricularWrapper";
import StaffPerformance from "../Pages/CoCurricular/StaffPerformance";
import StudentPerformance from "../Pages/CoCurricular/StudentPerformance";
import Infrastructure from "../Pages/Infrastructure";
import FixedAssets from "../Pages/FixedAssets";
import PromoteStudents from "../Pages/PromoteStudents";
import AssetWrapper from "../Pages/FixedAssets/AssetWrapper";
import AssetTransaction from "../Pages/FixedAssets/AssetTransaction";
import ClassWiseStudents from "../Pages/Reports/ClassWiseStudents";
import OrphansStrength from "../Pages/Reports/OrphansStrength";
import MaleAndFemaleRatio from "../Pages/Reports/MaleAndFemaleRatio";
import DropoutStudents from "../Pages/Reports/DropoutStudents";
import StudentWiseMarkSheet from "../Pages/Reports/StudentWiseMarkSheet";
import NewAdmissionsSchoolWise from "../Pages/Reports/NewAdmissionsSchoolWise";
import NewAdmissionsClusterWise from "../Pages/Reports/NewAdmissionsClusterWise";
import DropoutStudentsSchoolWise from "../Pages/Reports/DropoutStudentsSchoolWise";
import PassoutStudentsSchoolWise from "../Pages/Reports/PassoutStudentsSchoolWise";
import StudentTestResultsReport from "../Pages/Reports/StudentTestResultsReport";
import AllSubjectTestResults from "../Pages/Reports/AllSubjectTestResults";
import StudentsByGradeClusterWise from "../Pages/Reports/StudentsByGradeClusterWise";
import StudentsByGradeSchoolClassWise from "../Pages/Reports/StudentsByGradeSchoolClassWise";
import AssetObservation from "../Pages/FixedAssets/AssetObservation";
import FeeVoucher from "../Pages/Fee/FeeVoucher";
import StudentsDropoutsSessionWise from "../Pages/Reports/StudentsDropoutsSessionWise";
import TeacherAttendaceSummary from "../Pages/Reports/TeacherAttendanceSummary";
import TestResultAttendance from "../Pages/Reports/ClassWiseSubjectStudentPresentAbsentExam";
import ActiveStudentDetails from "../Pages/Reports/ActiveStudentDetails";
import ActiveStudentAttendanceStats from "../Pages/Reports/ActiveStudentAttendanceStats";
import ListOfTeachersObservationNotDone from "../Pages/Reports/ListOfTeacherObservationNotDone";
import PIVOTResultTeacherClassObservation from "../Pages/Reports/TeacherClassObservationResultPIVOT";
import React, { useEffect } from "react";
import QuitStudentsSchoolWise from "../Pages/Reports/QuitStudentsSchoolWise";
import AssetPhysicalCondition from "../Pages/Reports/AssetPhysicalCondition";

export default function Routes(props) {
  return (
    <DomRoutes>
      <Route path="/" element={<App />}>
        {window.sessionStorage.getItem("workStation") == "admin" ? (
          <Route index element={<AdminDashboard />} />
        ) : (
          <Route index element={<Dashboard />} />
        )}{" "}
        <Route path="/clusters" element={<ClustersWrapper />}>
          <Route index element={<Clusters />} />
          <Route path="details/:id" element={<ClusterDetails />} />
          <Route path="school/:id" element={<SchoolDetails />} />
        </Route>
        <Route path="/class-subjects" element={<ClassSubject />} />
        <Route index path="/staff" element={<Staff />} />
        <Route path="staffDetails/:id" element={<StaffDeatils />} />
        <Route index path="/user-management" element={<Users />} />
        <Route path="/test-management" element={<TestWrapper />}>
          <Route index element={<Test />} />
          <Route path="results" element={<TestResults />} />
        </Route>
        <Route path="/syllabus" element={<SyllabusWrapper />}>
          <Route index element={<Syllabus />} />
          <Route path="add" element={<SyllabusAddOrView />} />
        </Route>
        <Route index path="/attendance" element={<Attendance />} />
        <Route index path="/staff-attendance" element={<StaffAttendance />} />
        <Route index path="/school-assembly" element={<SchoolAssembly />} />
        <Route index path="/fee-management" element={<FeeVoucher />} />
        <Route index path="/fee-management-old" element={<Fee />} />
        <Route index path="/variable-management" element={<Variables />} />
        <Route path="/library" element={<LibraryWrapper />}>
          <Route index element={<Library />} />
          <Route path="transaction" element={<Transactions />} />
        </Route>
        <Route path="/workshop" element={<WorkShopWrapper />}>
          <Route index element={<WorkShop />} />
          <Route path="participant" element={<Participant />} />
        </Route>
        <Route path="user-management/:id" element={<UserDetails />} />
        <Route
          path="/weekly-assembly-record"
          element={<WeeklyAssemblyRecord />}
        />
        {/*<Route
          path="/class-observation-form"
          element={<ClassObservationForm />}
        />*/}
        <Route path="/class-observation" element={<ClassObservationForm />} />
        <Route path="/school-record" element={<SchoolRecord />} />
        <Route path="/parent-interview" element={<ParentInterview />} />
        <Route path="/change-password-request" element={<PasswordRequest />} />
        <Route path="/infrastructure" element={<Infrastructure />} />
        <Route path="/co-curricular" element={<CoCurricularWrapper />}>
          <Route index element={<CoCurricular />} />
          {/*<Route path="staff-performance/:id" element={<AddStudents />} />*/}
          <Route path="staff-performance" element={<StaffPerformance />} />
          <Route path="student-performance" element={<StudentPerformance />} />
        </Route>
        <Route path="/fixed-assets" element={<AssetWrapper />}>
          <Route index element={<FixedAssets />} />]
          <Route
            path="fixed-asset-transaction"
            element={<AssetTransaction />}
          />
          <Route
            path="fixed-asset-observation"
            element={<AssetObservation />}
          />
        </Route>
        <Route path="/CAP" element={<Cap />} />
        <Route path="/promote-students" element={<PromoteStudents />} />
        <Route path="/PTM" element={<Ptm />} />
        <Route path="/Rollback" element={<Rollback />} />
        <Route path="/students" element={<StudentsWrapper />}>
          <Route index element={<Students />} />
          <Route path="add" element={<AddStudents />} />
          <Route path="details/:id" element={<StudentDetails />} />
          <Route path="edit/:id" element={<AddStudents />} />
        </Route>
        <Route path="/reports" element={<ReportsWrapper />}>
          <Route
            path="student-by-grade-school-class-wise-report"
            element={<StudentsByGradeSchoolClassWise />}
          />
          <Route
            path="student-by-grade-cluster-wise-report"
            element={<StudentsByGradeClusterWise />}
          />
          <Route
            path="student-all-subject-test-results-report"
            element={<AllSubjectTestResults />}
          />
          <Route
            path="student-test-results-report"
            element={<StudentTestResultsReport />}
          />
          <Route
            path="passout-student-school-wise"
            element={<PassoutStudentsSchoolWise />}
          />
          <Route
            path="dropout-student-school-wise"
            element={<DropoutStudentsSchoolWise />}
          />
          <Route
            path="new-admissions-school-wise"
            element={<NewAdmissionsSchoolWise />}
          />
          <Route
            path="new-admissions-cluster-wise"
            element={<NewAdmissionsClusterWise />}
          />
          <Route
            path="dropouts-session-wise"
            element={<StudentsDropoutsSessionWise />}
          />
          <Route
            path="teacher-attendance-summary"
            element={<TeacherAttendaceSummary />}
          />
          <Route
            path="testresult-present-absent-count-schoolwise"
            element={<TestResultAttendance />}
          />
          <Route
            path="active-student-report-school-wise"
            element={<ActiveStudentDetails />}
          />
          <Route
            path="student-attendance-stats-date-range-school-wise"
            element={<ActiveStudentAttendanceStats />}
          />
          <Route
            path="list-of-observation-not-done-class-teacher-observation-list-periodic"
            element={<ListOfTeachersObservationNotDone />}
          />
          <Route
            path="pivot-result-teacher-class-observation-period"
            element={<PIVOTResultTeacherClassObservation />}
          />
          <Route
            path="quit-student-school-wise"
            element={<QuitStudentsSchoolWise />}
          />
          <Route path="mark-sheet" element={<StudentWiseMarkSheet />} />
          <Route path="dropout-students" element={<DropoutStudents />} />
          <Route path="male-female-ratio" element={<MaleAndFemaleRatio />} />
          <Route path="orphans-strength" element={<OrphansStrength />} />
          <Route path="class-wise-students" element={<ClassWiseStudents />} />
          <Route path="daily-entries" element={<DailyEntries />} />
          <Route path="fee-collection-v1" element={<FeeCollectionV1 />} />
          <Route path="fixed-assets-condition" element={<AssetPhysicalCondition />} />
        </Route>
      </Route>
      <Route caseSensitive path="/login" element={<Login />} />
    </DomRoutes>
  );
}
