import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Menu,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    TextField,
    Typography,
  } from "@mui/material";
  import { Box, Stack } from "@mui/system";
  import React, { useEffect, useState } from "react";
  import { TextareaAutosize } from "@mui/base/TextareaAutosize";
  import Table from "@mui/material/Table";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
  import { useLocation } from "react-router-dom";
  import dayjs from "dayjs";
  import axiosInstance from "../../Api/axios.instance";
  import { LoadingButton } from "@mui/lab";
  import { IconButton } from "@mui/material";
  import MoreVertIcon from "@mui/icons-material/MoreVert";
  import useSession from "../../CustomHooks/useSession";
  import MuiAlert from "@mui/material/Alert";
  import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
  import FileDownloadIcon from "@mui/icons-material/FileDownload";
  import { getClasses } from "../ClassSubjects/Classes";
  import { jsPDF } from "jspdf";
  import "jspdf-autotable";
  import * as XLSX from "xlsx";
  import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
  
  export default function QuitStudentsSchoolWise() {
    const [classes, setClasses] = useState([]);
    const [students, setStudents] = useState([]);
    const [clusters, setClusters] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const { currentPage, accesLevel, access } = useSession();
    const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
    const [formData, setFormData] = useState({
      from: dayjs().format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
  
    const _onSubmit = async (e) => {
      e.preventDefault();
      console.log("formData ===>", formData);
      const searchParams = new URLSearchParams();
      if (accesLevel === "ADMIN") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        searchParams.append("school_id", schoolID);
      }
      if (accesLevel === "SCHOOL") {
        const accessObject = Object.assign({}, access);
        const schoolID = accessObject[0].SchoolId;
        searchParams.append("school_id", schoolID);
      }
      if (accesLevel === "CLUSTER") {
        const schoolID = JSON.parse(localStorage.getItem("UserAccess"))[0]
          .SchoolId;
        searchParams.append("school_id", schoolID);
      }
      searchParams.append("from", formData.from);
      searchParams.append("to", formData.to);
      try {
        const { data, status } = await axiosInstance.get(
          "/reports/quit-student-school-wise",
          {
            params: searchParams,
          }
        );
        if (status === 200) {
          setStudents(data.data);
        }
        console.log("INACTIVE HAIN YEH",data.data);
      } catch (error) {
        console.log(error);
      }
    };
  
    //   const _handleOnAdminClusterChange = (e) => {
    //     const cluster = clusters.filter((item) => item.RegionId === e.target.value);
    //     console.log("cluster", cluster);
    //     console.log("schools", cluster[0].School);
    //     setSchoolList(cluster[0].School);
    //   };
    const _handleOnChange = (e) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          [e.target.name]: e.target.value,
        };
      });
    };
  
    const _handleDateFrom = (e) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          from: dayjs(e).format("YYYY-MM-DD"),
        };
      });
    };
  
    const _handleDateTo = (e) => {
      setFormData((prevState) => {
        return {
          ...prevState,
          to: dayjs(e).format("YYYY-MM-DD"),
        };
      });
    };
  
    const exportPDF = () => {
      const doc = new jsPDF({ orientation: "landscape" });
  
      doc.autoTable({
        html: "#my-table",
      });
  
      doc.save("Baithak-Report.pdf");
    };
  
    const exportXLSX = () => {
      handleExport().then((url) => {
        console.log(url);
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute(
          "download",
          "student-strength-report.xlsx"
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      });
    };
  
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
  
      const view = new Uint8Array(buf);
  
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i);
      }
  
      return buf;
    };
  
    const workbook2Blob = (workbook) => {
      const wopts = {
        bookType: "xlsx",
        type: "binary",
      };
      const wbOut = XLSX.write(workbook, wopts);
  
      const blob = new Blob([s2ab(wbOut)], {
        type: "application/octet-stream",
      });
  
      return blob;
    };
  
    const handleExport = () => {
      let baithakLogo = [{}];
  
      let title = [{ A: "Report Quit or Inactive Students Class Wise" }, {}];
  
      let table = [
        {
          A: "S.No",
          B: "Student Name",
          C: "Father Name",
          D: "GRNO",
          E: "Class in Admission",
          F: "Date of Admission",
          G: "Date of Quit",
          H: "Current Class",
        },
      ];
  
      students.length > 0 &&
        students.forEach((student, index) => {
          table.push({
            A: index + 1,
            B: student.StudentName,
            C: student.FathersName,
            D: student.GRNO,
            E: student.ClassModel ? student.ClassModel.ClassName : "",
            F: student.Date_Of_Addmission,
            G: student.Dropout_Date,
            H: student.ClassModel ? student.ClassModel.ClassName : "",
          });
        });
  
      table = [{ A: "Student Strength" }].concat(table).concat([""]);
  
      const finalData = [...title, ...table];
  
      // create workbook
      const wb = XLSX.utils.book_new();
  
      // create worksheet
      const sheet = XLSX.utils.json_to_sheet(finalData, {
        skipHeader: true,
      });
  
      XLSX.utils.book_append_sheet(wb, sheet, "report-student-strength");
  
      const workbookBlob = workbook2Blob(wb);
  
      const headerIndexes = [];
      finalData.forEach((data, index) =>
        data["A"] === "S.No" ? headerIndexes.push(index) : null
      );
  
      const dataInfo = {
        titleCell: "A2",
        titleRange: "A1:H2",
        tbodyRange: `A3:H${finalData.length}`,
        theadRange:
          headerIndexes.length >= 1
            ? `A${headerIndexes[0] + 1}:H${headerIndexes[0] + 1}`
            : null,
      };
      return addStyles(workbookBlob, dataInfo);
    };
  
    const addStyles = (workbookBlob, dataInfo) => {
      return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
        workbook.sheets().forEach((sheet) => {
          // sheet.usedRange.style({
          //   fontFamily: "Arial",
          //   verticalAlignment: "center",
          // });
  
          sheet.column("A").width(15);
          sheet.column("B").width(20);
          sheet.column("C").width(20);
          sheet.column("D").width(15);
          sheet.column("E").width(15);
          sheet.column("F").width(15);
          sheet.column("G").width(15);
  
          sheet.range(dataInfo.titleRange).merged(true).style({
            bold: true,
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });
  
          sheet.range(dataInfo.titleRange).style({
            horizontalAlignment: "center",
            verticalAlignment: "center",
          });
  
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "left",
            verticalAlignment: "center",
          });
  
          sheet.range(dataInfo.theadRange).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "left",
            verticalAlignment: "center",
          });
        });
  
        return workbook
          .outputAsync()
          .then((workbookBlob) => URL.createObjectURL(workbookBlob));
      });
    };
  
    return (
      <Box flexGrow={1}>
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Typography variant="h6" component="h1">
              Quit Students School Wise
            </Typography>
            {/*<Typography variant="subtitle2" component="span">
                      Select Date to generate a new Daily Entries Report
                    </Typography>*/}
          </Stack>
          <Box width={300}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                {/*<TextField
                    id="standard-select-currency"
                    select
                    label="Class"
                    sx={{ display: "flex", flex: 1 }}
                    variant="outlined"
                    // value={selectedTest.ClassID}
                    name="ClassID"
                    onChange={_handleClassChange}
                    size="small"
                  >
                    {classes.map((item, i) => {
                      return (
                        <MenuItem key={i} value={item.ID}>
                          {item.ClassName}
                        </MenuItem>
                      );
                    })}
                  </TextField>*/}
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Divider sx={{ mt: 1, mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Box textAlign="right">
              <Button
                variant="outlined"
                color="primary"
                sx={{ mr: 1 }}
                onClick={exportPDF}
              >
                <PictureAsPdfIcon color="primary" />
              </Button>
              <Button onClick={exportXLSX} variant="outlined" color="primary">
                <FileDownloadIcon color="primary" />
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2}>
          {/*<Grid item xs={12} md={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Select Cluster"
              sx={{ display: "flex", flex: 1 }}
              variant="outlined"
              // value={selectedTest.ClassID}
              name="RegionId"
              onChange={_handleOnAdminClusterChange}
              size="small"
            >
              {clusters.map((cluster, index) => {
                return (
                  <MenuItem key={index} value={cluster.RegionId}>
                    {cluster.RegionName}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Select School"
              sx={{ display: "flex", flex: 1 }}
              variant="outlined"
              // value={selectedTest.ClassID}
              name="SchoolID"
              onChange={_handleOnChange}
              size="small"
            >
              {schoolList.map((school, index) => {
                return (
                  <MenuItem key={index} value={school.SchoolId}>
                    {school.NameOfSchool}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>*/}
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                inputFormat="DD-MM-YYYY"
                label="Date From"
                name="from"
                value={formData.from}
                onChange={_handleDateFrom}
                renderInput={(params) => (
                  <TextField
                    sx={{ display: "flex", flex: 1 }}
                    size="small"
                    required
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                inputFormat="DD-MM-YYYY"
                label="Date To"
                name="to"
                value={formData.to}
                onChange={_handleDateTo}
                renderInput={(params) => (
                  <TextField
                    sx={{ display: "flex", flex: 1 }}
                    size="small"
                    required
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={4}>
            <LoadingButton
              //   loading={loading}
              //   disabled={loading}
              type="submit"
              onClick={_onSubmit}
              fullWidth
              variant="contained"
              color="primary"
            >
              Get Quit Student List{" "}
            </LoadingButton>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Stack
          sx={{ mb: 2 }}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div style={{ width: "100%" }}>
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                id="my-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell align="center">Student Name</TableCell>
                    <TableCell align="center">Father Name</TableCell>
                    <TableCell align="center">GRNO</TableCell>
                    <TableCell align="center">Gender</TableCell>
                    <TableCell align="center">
                      Class At the time of Admission
                    </TableCell>
                    <TableCell align="center">
                      Class At the time of Leaving
                    </TableCell>
                    <TableCell align="center">Date of Admission</TableCell>
                    <TableCell align="center">Date of Quit</TableCell>
                    <TableCell align="right">Reason of Leaving</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.length > 0 &&
                    students.map((student, index) => {
                      return (
                        <TableRow
                          key={String(index)}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {student.StudentName}
                          </TableCell>
                          <TableCell align="center">
                            {student.FathersName}
                          </TableCell>
                          <TableCell align="center">{student.GRNO}</TableCell>
                          <TableCell align="center">{student.Gender}</TableCell>
                          <TableCell align="center">
                            {student.ClassAtTheTimeOfAdmissionModel
                              ? student.ClassAtTheTimeOfAdmissionModel.ClassName
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {student.ClassModel
                              ? student.ClassModel.ClassName
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {student.Date_Of_Addmission}
                          </TableCell>
                          <TableCell align="center">
                          {dayjs(student.Dropout_Date).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell align="right">
                            {student.Reason_Leaved}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Stack>
      </Box>
    );
  }
  